export default {
  en: {
    $vuetify: {
      badge: "Badge",
      open: "Open",
      close: "Close",
      dataIterator: {
        noResultsText: "No matching records found",
        loadingText: "Loading items...",
      },
      dataTable: {
        itemsPerPageText: "Rows per page:",
        ariaLabel: {
          sortDescending: "Sorted descending.",
          sortAscending: "Sorted ascending.",
          sortNone: "Not sorted.",
          activateNone: "Activate to remove sorting.",
          activateDescending: "Activate to sort descending.",
          activateAscending: "Activate to sort ascending.",
        },
        sortBy: "Sort by",
      },
      dataFooter: {
        itemsPerPageText: "Items per page:",
        itemsPerPageAll: "All",
        nextPage: "Next page",
        prevPage: "Previous page",
        firstPage: "First page",
        lastPage: "Last page",
        pageText: "{0}-{1} of {2}",
      },
      dateRangeInput: {
        divider: "to",
      },
      datePicker: {
        ok: "OK",
        cancel: "Cancel",
        range: {
          title: "Select dates",
          header: "Enter dates",
        },
        title: "Select date",
        header: "Enter date",
        input: {
          placeholder: "Enter date",
        },
      },
      noDataText: "No data available",
      carousel: {
        prev: "Previous visual",
        next: "Next visual",
        ariaLabel: {
          delimiter: "Carousel slide {0} of {1}",
        },
      },
      calendar: {
        moreEvents: "{0} more",
      },
      input: {
        clear: "Clear {0}",
        prependAction: "{0} prepended action",
        appendAction: "{0} appended action",
        otp: "Please enter OTP character {0}",
      },
      fileInput: {
        counter: "{0} files",
        counterSize: "{0} files ({1} in total)",
      },
      timePicker: {
        am: "AM",
        pm: "PM",
      },
      pagination: {
        ariaLabel: {
          root: "Pagination Navigation",
          next: "Next page",
          previous: "Previous page",
          page: "Go to page {0}",
          currentPage: "Page {0}, Current page",
          first: "First page",
          last: "Last page",
        },
      },
      stepper: {
        next: "Next",
        prev: "Previous",
      },
      rating: {
        ariaLabel: {
          item: "Rating {0} of {1}",
        },
      },
      loading: "Loading...",
      infiniteScroll: {
        loadMore: "Load more",
        empty: "No more",
      },
      confirmEdit: {
        cancel: "Cancel",
        ok: "Ok",
      },
    },
  },
  ar: {
    $vuetify: {
      badge: "شارة",
      open: "فتح",
      close: "إغلاق",
      dataIterator: {
        noResultsText: "لا توجد نتائج مطابقة",
        loadingText: "جاري التحميل...",
      },
      dataTable: {
        itemsPerPageText: "عدد الصفوف لكل صفحة:",
        ariaLabel: {
          sortDescending: "مرتب تنازلياً.",
          sortAscending: "مرتب تصاعدياً.",
          sortNone: "غير مرتب.",
          activateNone: "نشط لإزالة الترتيب.",
          activateDescending: "نشط للترتيب تنازلياً.",
          activateAscending: "نشط للترتيب تصاعدياً.",
        },
        sortBy: "رتب حسب",
      },
      dataFooter: {
        itemsPerPageText: "عدد العناصر لكل صفحة:",
        itemsPerPageAll: "الكل",
        nextPage: "الصفحة التالية",
        prevPage: "الصفحة السابقة",
        firstPage: "الصفحة الأولى",
        lastPage: "الصفحة الأخيرة",
        pageText: "{0}-{1} من {2}",
      },
      dateRangeInput: {
        divider: "إلي",
      },
      datePicker: {
        ok: "موافق",
        cancel: "إلغاء",
        range: {
          title: "حدد التواريخ",
          header: "أدخل التواريخ",
        },
        title: "حدد التاريخ",
        header: "أدخل التاريخ",
        input: {
          placeholder: "أدخل التاريخ",
        },
      },
      noDataText: "لا توجد بيانات",
      carousel: {
        prev: "المعروض السابق",
        next: "المعروض التالي",
        ariaLabel: {
          delimiter: "المعروض رقم {0} من {1}",
        },
      },
      calendar: {
        moreEvents: "{0} أكثر",
      },
      input: {
        clear: "مسح {0}",
        prependAction: "{0} الإجراء المسبق",
        appendAction: "{0} الإجراء اللاحق",
        otp: "الرجاء إدخال رمز التحقق {0}",
      },
      fileInput: {
        counter: "{0} ملفات",
        counterSize: "{0} ملفات ({1} في المجموع)",
      },
      timePicker: {
        am: "صباحاً",
        pm: "مساءً",
      },
      pagination: {
        ariaLabel: {
          root: "الإنتقال بين الصفحات",
          next: "الصفحة التالية",
          previous: "الصفحة السابقة",
          page: "{0} انتقل إلى الصفحة",
          currentPage: "{0} الصفحة الحالية رقمها",
          first: "الصفحة الأولى",
          last: "الصفحة الأخيرة",
        },
      },
      stepper: {
        next: "التالي",
        prev: "السابق",
      },
      rating: {
        ariaLabel: {
          item: "القييم {0} من {1}",
        },
      },
      loading: "جاري التحميل...",
      infiniteScroll: {
        loadMore: "تحميل المزيد",
        empty: "لا يوجد المزيد",
      },
      confirmEdit: {
        cancel: "الغاء",
        ok: "تأكيد",
      },
    },
  },
};
